import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, type = "type1", className, ...property }) => {
  const contentType = {
    type0: "type0",
    type1: "type1",
    type2: "type2",
    type3: "type3",
    type4: "type4",
    type5: "type5",
    type6: "type6",
    type7: "type7",
    type8: "full",
    type9: "full",
    type10: "full",
    type11: "full",
  };
  const containerStyle = {
    type0: "w-11/12 md:max-w-[1180px] mx-auto",
    type1: "w-full md:max-w-[1180px] mx-auto px-6",
    type2: "w-full md:max-w-[780px] mx-auto px-6",
    type3: "w-full md:max-w-3xl mx-auto",
    type4: "w-full md:max-w-129.752 mx-auto",
    type5: "w-full md:max-w-211.5 mx-auto",
    type6: "w-full md:max-w-48.5 mx-auto",
    type7: "w-full md:max-w-157 mx-auto",
    type8: "w-full md:max-w-130 mx-auto",
    type9: "w-full md:max-w-75.5 mx-auto",
    type10: "w-11/12 md:max-w-[1193px] mx-auto",
    type11: "w-full md:max-w-[1292px] mx-auto px-4",
    type12: "w-full md:max-w-[1292px] mx-auto px-4",
    type13: "w-11/12 md:max-w-[1292px] mx-auto px-4",

    full: "w-full max-w-full mx-auto",
  };
  return (
    <div
      type={contentType[type]}
      className={` ${containerStyle[type]} ${className || ""}`}
      {...property}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  type:PropTypes.string,
  className: PropTypes.string,
};

export default Container;
