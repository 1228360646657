import {
    CREATE_SEND_MESSAGE,
    CREATE_SEND_CONTACT_MESSAGE_FOR_EDUCATION,
    SEND_OTP_TO_SUNSHINE_USER,
    VERIFY_OTP_TO_SUNSHINE_USER,
    CREATE_SUNSHINE_CENTER_RESPONSE
} from './url.service';
import * as HttpService from "./http.service";

const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const createSendMessage = (details) => {
    return HttpService.postWithAuth(CREATE_SEND_MESSAGE(), details);
};

export const createSendContactMessageForEducation = (details) => {
    return HttpService.postWithAuth(CREATE_SEND_CONTACT_MESSAGE_FOR_EDUCATION(), details);
};

export const sendOtpToSunshineUser = (details) => {
    return HttpService.postWithAuth(SEND_OTP_TO_SUNSHINE_USER(), details);
};

export const verifyOtpToSunshineUser = (mobile,otp) => {
    return HttpService.postWithAuth(VERIFY_OTP_TO_SUNSHINE_USER(), {mobile,otp});
};

export const createSunshineVisitCenterResponse = (details) => {
    return HttpService.postWithAuth(CREATE_SUNSHINE_CENTER_RESPONSE(), details);
};
