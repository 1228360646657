import React from "react";
import PropTypes from "prop-types";
import Input from '@atoms/Input'

function InputGroup({ className, label, type, placeholder, name, register, errors, inputClassName, textareaClassName }) {
    return (
        <div className={`relative  ${className}`}>
            {label && <label className=''>{label}</label>}
            {
                type === 'textarea' ? (
                    <textarea
                        placeholder={placeholder}
                        name={name}
                        {...register(name)}
                        className={`h-24 rounded-lg bg-white px-4 w-full py-2 outline-none border ${textareaClassName}`}
                    />
                ) : (
                    <Input
                        type={type}
                        placeholder={placeholder}
                        name={name}
                        register={register}
                        className={inputClassName}
                    />
                )
            }
            {errors[name] && <div className={` text-xs text-[#f44336]  lowercase first-letter:capitalize mt-1  `}> {errors[name].message} </div>}
        </div>
    )
}

export default InputGroup

InputGroup.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    register: PropTypes.func,
    errors: PropTypes.object,
    inputClassName: PropTypes.string,
    textareaClassName: PropTypes.string,
};